/* You can add global styles to this file, and also import other style files */

html,
body{
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  min-width: 300px;
}

.content{
  color: #333;
}

.content > div{
  border: 1px solid transparent;
  width: 70%;
  margin: 0 auto;
}

.content h2,
.content h3{
  font-weight: 300;
}

.content h2{
  font-size: 2em;
  color: #333;
  padding-bottom: 0.5em;
  border-bottom: 1px solid rgba(0,144,255,0.25);
}
.content p{
  text-align: justify;
}
p.left-indent{
  padding-left: 3em;
}

@media (max-width: 1200px) {

  .content > div{
    width: 90%;
  }

}

.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 45vh auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #1560a0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
}
